import React, {useRef, useEffect, useState, createRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetHelperFunction } from '../store/schemas/helperFunctionsSlice';
const ThunkNavigation = () => {
  const naviate = useNavigate()
  const thunkNavigate = useSelector(state => state.mainapp.helperFunctions.nav)
  const dispatch = useDispatch()
  useEffect(() => {
    if(thunkNavigate != null){
        naviate(thunkNavigate.url, {replace:thunkNavigate.replace})
        dispatch(resetHelperFunction())
    }
  }, [thunkNavigate])

}

export default ThunkNavigation;